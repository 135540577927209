
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


































.checkblock-container {
  margin-right: 4rem;
}
input[type='radio'] {
  display: none;
}

input[type='radio'] + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  display: block;
  color: rgb(83, 83, 86);

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    border: 1px solid #d1d1d6;
  }

  &:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
    background: transparent;
    transition: 0.1s ease-in;
  }
}

input[type='radio']:checked + label:after {
  background: #e84e0f;
}
