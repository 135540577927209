
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        






















































































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.input {
  display: grid;
  grid-template-columns: 140px 350px;
  border: 0;
  align-items: center;
}

.page-header {
  margin-bottom: 4rem;
  margin-top: 4rem;

  @include mq(m) {
    margin-top: unset;
  }
}

.submitAdress {
  @include loading($c-green);
}

.confirm-text {
  display: flex;
  color: $c-green;
  fill: $c-green;
}

.text-gray {
  color: $c-gray-darker;
  display: contents;
}

.confirm-follow {
  @include mq(m) {
    display: flex;
  }
}
